<template>
  <div class="card">
    <div class="card-body">
      <a href="#" class="btn btn-primary btn-xs float-right" @click.prevent="show_add_template = true"><i class="lni-plus mr-2"></i>Add Template</a>
      <h4 class="card-title">Message Templates</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Content</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(template, i) in templates" :key="`template-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ template.name }}</td>
            <td>{{ template.content }}</td>
            <td>
              <a href="#" class="btn btn-danger btn-xs" @click.prevent="remove(template)"><i class="lni-trash"></i></a>
            </td>
          </tr>
          <tr v-if="templates.length == 0">
            <td colspan="4">There is no data to display</td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal :show="show_add_template">
      <template slot="header">Add Message Template</template>
      <div class="alert alert-info">
        You may create programmable sms using an uploaded excel file and use the column titles for your data, eg, in an excel file that has a name column, use @name
      </div>
      <form @submit.prevent="submit">
        <div class="form-group">
          <label>Name</label>
          <input type="text" required placeholder="Name" class="form-control" v-model="new_template.name">
        </div>
        <div class="form-group">
          <label>Template</label>
          <textarea class="form-control" required placeholder="Template content" rows="4" v-model="new_template.content"></textarea>
        </div>
        <div class="form-group text-right">
          <button class="btn btn-primary btn-sm"><i class="lni-save mr-2"></i>Save</button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      show_add_template: false,
      templates: [],
      new_template: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')
      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/templates`).then(response => {
        this.templates = response.data
        this.$loader.stop()
      })
    },

    submit() {
      this.$loader.start('main-content')
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/templates`, this.new_template).then(response => {
        this.show_add_template = false
        this.templates = response.data
        this.$loader.stop()
      })
    },

    remove(template) {
      this.$loader.start('main-content')
      this.$axios.delete(`/api/v1/apps/${this.$route.params.id}/templates/${template.id}`).then(response => {
        this.templates = response.data
        this.$loader.stop()
      })
    }
  }
}
</script>